import { useState, useEffect, useRef } from 'react';
import { db } from '../firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useUser } from '../contexts/UserContext';
import DLCOverview from './DLCOverview';
import ReceptionOverview from './ReceptionOverview';

function Overview() {
  const [today] = useState(new Date().toISOString().slice(0, 10));
  const [fridges, setFridges] = useState([]);
  const [icon, setIcon] = useState();
  const { enterpriseId } = useUser();
  const cachedData = useRef({});

  useEffect(() => {
    if (!enterpriseId) return;

    const fridgesRef = collection(db, 'tempFrigo');
    const q = query(fridgesRef, where('enterpriseId', '==', enterpriseId));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fridgesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      cachedData.current[enterpriseId] = fridgesData;
      setFridges(fridgesData);
      evaluateStatus(fridgesData);
    });

    return () => unsubscribe();
  }, [enterpriseId]);

  const checkTemperature = (temperatures) => temperatures && temperatures[today] !== undefined;

  const evaluateStatus = (fridgesData) => {
    const results = fridgesData.map((fridge) => checkTemperature(fridge.temperatures));
    const allDone = results.every((status) => status);
    const someDone = results.some((status) => status);

    if (allDone) {
      setIcon(<CheckCircleIcon className="w-6 h-6 text-green-500" />);
    } else if (someDone) {
      setIcon(<ExclamationCircleIcon className="w-6 h-6 text-orange-500" />);
    } else {
      setIcon(<XCircleIcon className="w-6 h-6 text-red-500" />);
    }
  };

  return (
    <>
      {/* Section Relevé des températures */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="rounded-lg shadow-lg p-6 mb-8 bg-white">
          <h3 className="text-2xl font-bold text-gray-900 mb-6 flex items-center justify-center gap-4">
            Relevé des températures {icon}
          </h3>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {fridges.map((fridge) => (
              <div
                key={fridge.id}
                className="relative p-6 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-200"
              >
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-xl font-semibold text-gray-800">{fridge.fridgeName}</h3>
                  {checkTemperature(fridge.temperatures) ? (
                    <CheckCircleIcon className="w-6 h-6 text-green-500" />
                  ) : (
                    <XCircleIcon className="w-6 h-6 text-red-500" />
                  )}
                </div>
                <p className="text-sm text-gray-600">
                  Relevé du jour :{' '}
                  {checkTemperature(fridge.temperatures)
                    ? `${fridge.temperatures[today]}°C`
                    : 'Non effectué'}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Séparateur */}
      <hr className="my-8 border-gray-200" />

      {/* Section Suivi des DLC */}
      <DLCOverview />

      {/* Séparateur */}
      <hr className="my-8 border-gray-200" />

      {/* Section Dernières réceptions */}
      <ReceptionOverview />
    </>
  );
}

export default Overview;
