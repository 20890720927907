import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Assurez-vous que db est votre instance Firestore

const SubActiveRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // L'utilisateur est connecté, obtenez son enterpriseID
        const userRef = doc(db, 'users', user.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            // Maintenant, vérifiez l'abonnement de l'entreprise
            const enterpriseID = userDoc.data().enterpriseID;
            const enterpriseRef = doc(db, 'enterprises', enterpriseID);
            const enterpriseDoc = await getDoc(enterpriseRef);
            
            if (enterpriseDoc.exists() && enterpriseDoc.data().subscriptionActive) {
              // L'abonnement de l'entreprise est actif, autoriser l'accès
              setIsLoading(false);
            } else {
              // L'abonnement de l'entreprise n'est pas actif, rediriger vers la page de mise à niveau
              navigate('/subscribe');
            }
          } else {
            // Document utilisateur manquant, rediriger vers la page de connexion
            navigate('/login');
          }
        } catch (error) {
          console.error("Erreur lors de la vérification de l'abonnement de l'entreprise:", error);
          navigate('/login'); // En cas d'erreur, rediriger vers la connexion
        }
      } else {
        // L'utilisateur n'est pas connecté, rediriger vers la page de connexion
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (isLoading) {
    return <div>Chargement...</div>; // Affichez un indicateur de chargement ou un composant similaire
  }

  return <>{children}</>;
};

export default SubActiveRoute;
