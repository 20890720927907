import React from 'react';

function formatDate(date) {
    return date ? new Date(date.seconds * 1000).toLocaleDateString("fr-FR") : 'Non spécifiée';
}

function ModalReception({ isOpen, onClose, reception }) {
    if (!isOpen || !reception) return null;  // Ajout de la vérification ici pour s'assurer que 'reception' est non nul

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg">
                <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold text-gray-900">Détails de la réception</h3>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
                        &#x2715;
                    </button>
                </div>
                <div className="mt-4">
                    {reception.image_url && (
                        <img src={reception.image_url} alt="Réception" className="w-full h-48 object-cover rounded-md mb-4"/>
                    )}
                    <p><strong>Fournisseur :</strong> {reception.supplierName}</p>
                    <p><strong>Date de réception :</strong> {formatDate(reception.receptionDate)}</p>
                    <p><strong>Température du produit :</strong> {reception.productTemperature} °C</p>
                    <p><strong>Conformité :</strong> {reception.nonConformity ? 'Non conforme' : 'Conforme'}</p>
                    {reception.nonConformity && reception.nonConformityDetails && (
                        <p><strong>Détails de non-conformité :</strong> {reception.nonConformityDetails}</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ModalReception;
