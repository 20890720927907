// TemperatureHistoryModal.js
import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

function TemperatureHistoryModal({ isOpen, onClose, fridge }) {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const filteredTemperatures = Object.entries(fridge.temperatures)
    .filter(([date]) => {
      const dt = new Date(date);
      return dt.getMonth() === parseInt(selectedMonth, 10) && dt.getFullYear() === parseInt(selectedYear, 10);
    })
    .sort((a, b) => {
      return new Date(b[0]) - new Date(a[0]);
    });

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl overflow-y-auto max-h-full">
        <div className="px-6 py-4 border-b flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-900">
            Historique des températures - {fridge.fridgeName}
          </h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="px-6 py-4">
          <div className="flex space-x-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Mois :</label>
              <select
                value={selectedMonth}
                onChange={handleMonthChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#003366] focus:ring-[#003366]"
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i} value={i}>
                    {new Date(0, i).toLocaleString('default', { month: 'long' })}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Année :</label>
              <select
                value={selectedYear}
                onChange={handleYearChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#003366] focus:ring-[#003366]"
              >
                {Array.from({ length: 5 }, (_, i) => (
                  <option key={i} value={new Date().getFullYear() - i}>
                    {new Date().getFullYear() - i}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 border">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Température (°C)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredTemperatures.length > 0 ? (
                  filteredTemperatures.map(([date, temperature]) => (
                    <tr key={date}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-900">
                        {new Date(date).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{temperature}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2" className="px-6 py-4 text-center text-sm text-gray-500">
                      Aucune donnée disponible pour cette période.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="px-6 py-4 border-t flex justify-end">
          <button
            onClick={onClose}
            className="bg-[#003366] hover:bg-[#3399FF] text-white font-semibold py-2 px-4 rounded-md shadow"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
}

export default TemperatureHistoryModal;
