import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { HeadersEmpty } from './HeaderEmpty';

export default function MoreInfo() {
  const navigate = useNavigate();

  return (
    
    <div className="bg-white">
    <HeadersEmpty scrollRefs={{}} /> {/* Inclure le header en haut de la page */}
    <div className="py-24 sm:py-24">
        
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none text-center">
          <h1 className=" text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Gestion Sanitaire pour les Restaurants</h1>
          <p className="mt-6 text-xl leading-8 text-gray-700">
            Nous offrons une solution complète pour la gestion des obligations sanitaires des établissements alimentaires, 
            comprenant des outils de suivi, de contrôle et de gestion.
          </p>
        </div>

        <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2 mx-auto">
          <div>
            <p>
              Notre plateforme en ligne permet une gestion efficace des dates limites de consommation, 
              le contrôle à la réception des marchandises, et la configuration d'alertes pour les éléments clés à surveiller.
            </p>
            <ul role="list" className="mt-8 space-y-8 text-gray-600">
              <li className="flex items-start gap-x-3">
                <CheckCircleIcon className="h-6 w-6 flex-none text-[#003366]" aria-hidden="true" />
                <span>
                  <strong className="font-semibold text-gray-900">Suivi des dates de consommation.</strong> 
                  Gérez facilement les dates limites de consommation de vos produits.
                </span>
              </li>
              <li className="flex items-start gap-x-3">
                <CheckCircleIcon className="h-6 w-6 flex-none text-[#003366]" aria-hidden="true" />
                <span>
                  <strong className="font-semibold text-gray-900">Contrôle des marchandises.</strong> 
                  Effectuez des contrôles de qualité dès la réception des produits.
                </span>
              </li>
              <li className="flex items-start gap-x-3">
                <CheckCircleIcon className="h-6 w-6 flex-none text-[#003366]" aria-hidden="true" />
                <span>
                  <strong className="font-semibold text-gray-900">Notifications et alertes.</strong> 
                  Recevez des notifications pour les actions à mener et les dates importantes.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <p>
              Notre solution est conçue pour s'adapter à toutes les tailles d'établissements, de la petite boutique au grand restaurant. 
              Avec des outils de reporting avancés, vous pouvez extraire et analyser des données en temps réel pour améliorer votre efficacité opérationnelle.
            </p>
            <p className="mt-8">
              Notre interface utilisateur intuitive et nos outils de gestion vous permettent de rester informé et de réagir rapidement 
              aux besoins de votre établissement, assurant ainsi une conformité continue avec les réglementations sanitaires en vigueur.
            </p>
          </div>
        </div>

        <div className="mt-16 max-w-3xl mx-auto text-center">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">Rejoignez-nous</h2>
          <p className="mt-4 text-lg leading-7 text-gray-700">
            Connectez-vous ou inscrivez-vous pour accéder à toutes les fonctionnalités de notre plateforme.
          </p>
          <div className="mt-6 flex justify-center gap-x-6">
            <button
              onClick={() => navigate('/login')}
              className="rounded-md bg-[#003366] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#3399FF]"
            >
              Connexion
            </button>
            <button
              onClick={() => navigate('/signup')}
              className="rounded-md bg-[#3399FF] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#003366]"
            >
              Inscription
            </button>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16 lg:pt-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            src="logirestau-dashview.PNG"
            alt="Dashboard screenshot"
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
