import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, onSnapshot, orderBy, doc, updateDoc } from 'firebase/firestore';
import { useUser } from '../contexts/UserContext';
import ModalDlc from './ModalDlc';
import EditDlcModal from './EditDlcModal';

function DLCDashboard() {
    const [dlcItems, setDlcItems] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterDate, setFilterDate] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const { enterpriseId } = useUser();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [currentDlc, setCurrentDlc] = useState(null);

    useEffect(() => {
        if (!enterpriseId) return;

        const conditions = [where("enterpriseId", "==", enterpriseId)];
        if (showArchived) {
            conditions.push(where("archived", "in", [true, false]));
        } else {
            conditions.push(where("archived", "==", false));
        }
        if (filterDate) conditions.push(where("expirationDate", ">=", new Date(filterDate)));

        const q = query(collection(db, "dlcInfo"), ...conditions, orderBy("expirationDate"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const items = querySnapshot.docs.map(doc => ({
                id: doc.id, 
                ...doc.data(),
                expirationDate: doc.data().expirationDate.toDate() // Assurez-vous de convertir les Timestamps en Date
            }));
            const filteredItems = searchTerm ? items.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())) : items;
            setDlcItems(filteredItems);
        });

        return () => unsubscribe();
    }, [enterpriseId, showArchived, filterDate, searchTerm]);

    const handleEditClick = (item, event) => {
        event.stopPropagation(); // Empêche l'activation de l'ouverture des détails
        setCurrentDlc(item);
        setEditModalOpen(true);
    };

    const toggleArchiveItem = async (item, event) => {
        event.stopPropagation();
        const dlcDocRef = doc(db, "dlcInfo", item.id);
        await updateDoc(dlcDocRef, {
            archived: !item.archived
        });
    };

    const handleSelectItem = (item) => {
        setSelectedItem(item);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
    };

    function formatDate(date) {
        if (date && typeof date.toDate === 'function') {
            return date.toDate().toLocaleDateString();
        } else if (date instanceof Date) {
            return date.toLocaleDateString();
        } else {
            console.error('Date provided is not a Firestore Timestamp or a JavaScript Date object:', date);
            return ''; // ou retourner une valeur par défaut ou gérer l'erreur d'une autre manière
        }
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Gestion des DLC</h1>
            <div className="flex justify-center gap-4 mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par nom..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-1/4 rounded-full border py-2 px-4 text-gray-600 shadow ring-1 ring-gray-300 placeholder-gray-400 focus:ring-indigo-500"
                />
                <input
                    type="date"
                    value={filterDate}
                    onChange={(e) => setFilterDate(e.target.value)}
                    className="w-1/4 rounded-full border py-2 px-4 text-gray-600 shadow ring-1 ring-gray-300 focus:ring-indigo-500"
                />
            </div>
            <label className="flex justify-center items-center space-x-2 mb-4">
                <input
                    type="checkbox"
                    checked={showArchived}
                    onChange={() => setShowArchived(!showArchived)}
                    className="rounded"
                />
                <span>Voir les archivés</span>
            </label>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Nom</th>
                            <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Date d'expiration</th>
                            <th scope="col" className="py-3 pl-3 pr-4 text-left text-sm font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {dlcItems.length === 0 ? (
                            <tr>
                                <td colSpan="3" className="py-4 pl-4 pr-3 text-sm text-gray-500 text-center">
                                    Vous trouverez ici les informations concernant les DLC en cours ou passé.
                                    <img className="w-96 mx-auto" src="/dlcempty.png" alt="dlc-empty" />
                                </td>
                            
                            </tr>
                        ) : (
                            dlcItems.map((item) => (
                                <tr key={item.id} onClick={() => handleSelectItem(item)} className="cursor-pointer">
                                    <td className="py-4 pl-4 pr-3 text-sm text-left text-gray-900">
                                        {item.name}
                                        {item.archived && <span className="ml-2 inline-block rounded-full bg-orange-200 px-2 text-xs text-gray-600">Archivé</span>}
                                    </td>
                                    <td className="px-3 py-4 text-sm text-left text-gray-500">{formatDate(item.expirationDate)}</td>
                                    <td className="py-4 text-left text-sm text-gray-500">
                                        <button onClick={(e) => {
                                            e.stopPropagation(); // Stop event propagation
                                            handleEditClick(item, e);
                                        }} className="text-indigo-600 hover:text-indigo-900 mr-4">
                                            Edit
                                        </button>
                                        <button onClick={(e) => toggleArchiveItem(item, e)} className="text-red-600 hover:text-red-800">
                                            {item.archived ? 'Désarchiver' : 'Archiver'}
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            {selectedItem && (
                <ModalDlc 
                    isOpen={!!selectedItem} 
                    onClose={handleCloseModal} 
                    title={selectedItem.name}
                    item={selectedItem}
                />
            )}
            {editModalOpen && (
                <EditDlcModal
                    isOpen={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    dlcItem={currentDlc}
                />
            )}
        </div>
    );
}

export default DLCDashboard;
