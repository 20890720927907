import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const frequencies = [
  { value: 'monthly', label: 'Mensuel', priceSuffix: '/mois' },
  { value: 'annually', label: 'Annuel', priceSuffix: '/an' },
]
const tiers = [
  {
    name: 'Café',
    id: 'tier-cafe',
    href: '/subscribe',
    price: { monthly: '29€', annually: '305€' }, // 11 mois + 1 mois gratuit avec l'option annuelle
    description: 'Notre plus petite offre, dimensionnée pour les petits restaurants. Souhaitant gérer leur activité simplement.',
    features: ['8 utilisateurs, deux enseignes', 'Support par mail', 'Gestion de commandes de base', 'Analyses de base'],
    featured: false,
    cta: 'Choisir ce plan',
  },
  {
    name: 'Bistrot',
    id: 'tier-bistrot',
    href: '/subscribe',
    price: { monthly: '49€', annually: '525€' }, // 11 mois + 1 mois gratuit avec l'option annuelle
    description: "Idéal pour les restaurateurs avec un grand nombre d'employés ou les chaines de restaurants.",
    features: ["50 utilisateurs, jusqu'à 5 enseignes", 'Support prioritaire', 'Gestion avancée des commandes', 'Analyses avancées', 'Automatisations marketing'],
    featured: false,
    cta: 'Choisir ce plan',
  },
  {
    name: 'Etoilé',
    id: 'tier-grande-brasserie',
    href: '/contact',
    price: 'Sur mesure',
    description: 'Support et infrastructures dédiés pour les établissements haut de gamme. Hotelerie, grande chaine etc...',
    features: ['Utilisateurs illimités', 'Support dédié 24/7', 'Gestion complète des commandes', 'Analyses personnalisées', 'Outils de reporting sur mesure'],
    featured: true,
    cta: 'Contacter nous',
  },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Pricing() {
  const [frequency, setFrequency] = useState(frequencies[0])

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-[#3399FF]">Tarifs</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Des prix pour tous les restaurateurs
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Un abonnement mensuel sans engament, vous pouvez changer de plan à tout moment. Tous nos plans incluent un essai gratuit de 30 jours.
        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-[#003366] text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-2.5 py-1'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured ? 'bg-[#003366] ring-gray-900' : 'ring-gray-200',
                'rounded-3xl p-8 ring-1 xl:p-10'
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-lg font-semibold leading-8'
                )}
              >
                {tier.name}
              </h3>
              <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6')}>
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    tier.featured ? 'text-white' : 'text-gray-900',
                    'text-4xl font-bold tracking-tight'
                  )}
                >
                  {typeof tier.price === 'string' ? tier.price : tier.price[frequency.value]}
                </span>
                {typeof tier.price !== 'string' ? (
                  <span
                    className={classNames(
                      tier.featured ? 'text-gray-300' : 'text-gray-600',
                      'text-sm font-semibold leading-6'
                    )}
                  >
                    {frequency.priceSuffix}
                  </span>
                ) : null}
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.featured
                    ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                    : 'bg-[#003366] text-white shadow-sm hover:bg-[#3399FF] focus-visible:outline-indigo-600',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                )}
              >
                {tier.cta}
              </a>
              <ul
                
                className={classNames(
                  tier.featured ? 'text-gray-300' : 'text-gray-600',
                  'mt-8 space-y-3 text-sm leading-6 xl:mt-10'
                )}
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className={classNames(tier.featured ? 'text-white' : 'text-indigo-600', 'h-6 w-5 flex-none')}
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
