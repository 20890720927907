import { useId , useState} from 'react'
const features = [
  {
    name: "Un vue d'ensemble des actions à mener.",
    description:
      'Retrouver en un seul écran toutes les actions éffectués ou restant à faire pour respecter vos obligations réglementaire.',
    icon: DeviceUserIcon,
    image: 'Screenshot_overview.png'
    
  },
  {
    name: 'Gerer les temperetures des frigos.',
    description: 'Gèrer les températures de vos frigos et congélateurs pour garantir la qualité de vos produits. Vous pouvez relever les températures manuellement ou les enregistrer automatiquement avec des sondes connectées.',
    icon: DeviceNotificationIcon,
    image: '/Screenshot_temp.png'
  },
  {
    name: 'Controler les dates limites de consommation.',
    description: 'Vous pouvez controler à l\'avance les dates limites de consommation de vos produits pour éviter les pertes et garantir la qualité de vos produits. Vous pouvez aussi recevoir des alertes pour les produits proches de leur DLC.',
    icon: DeviceTouchIcon,
    image: '/Screenshot_dlc.png'
  },
]

function DeviceUserIcon(props) {
    return (
      <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
        <circle cx={16} cy={16} r={16} fill="#003366" fillOpacity={0.2} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z"
          fill="#003366"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
          fill="#003366"
        />
      </svg>
    )
  }
  
  function DeviceNotificationIcon(props) {
    return (
      <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
        <circle cx={16} cy={16} r={16} fill="#737373" fillOpacity={0.2} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z"
          fill="#003366"
        />
        <path
          d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z"
          fill="#003366"
        />
      </svg>
    )
  }
  
  function DeviceTouchIcon(props) {
    let id = useId()
  
    return (
      <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
        <defs>
          <linearGradient
            id={`${id}-gradient`}
            x1={14}
            y1={14.5}
            x2={7}
            y2={17}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#737373" />
            <stop offset={1} stopColor="#D4D4D4" stopOpacity={0} />
          </linearGradient>
        </defs>
        <circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z"
          fill="#003366"
        />
        <path
          d="M7 22c0-4.694 3.5-8 8-8"
          stroke={`url(#${id}-gradient)`}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z"
          fill="#003366"
        />
      </svg>
    )
  }

export function Features() {
    
    const [currentImage, setCurrentImage] = useState('/logirestau-overview.PNG');
    const [isImageChanging, setIsImageChanging] = useState(false);

  // Fonction pour changer l'image et déclencher l'animation
  const changeImage = (newImage) => {
    setIsImageChanging(true); // Indique que l'image est en train de changer
    setCurrentImage(newImage); // Change l'image
    
    // Remet isImageChanging à false après un délai pour permettre à l'animation de s'exécuter
    setTimeout(() => setIsImageChanging(false), 500); // La durée correspond à la durée de l'animation CSS
  };
    const [selectedFeature, setSelectedFeature] = useState(null);
  return (
    <div className="overflow-hidden w-full bg-[#003366] py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">

            
        <div className="mt-16 flex flex-col items-center lg:flex-row lg:items-start justify-center lg:space-x-8 py-8">
  <div className="grid grid-cols-1 gap-6 w-full max-w-md lg:max-w-none">
  {features.map((feature) => (
  <div
    key={feature.name}
    className={`flex flex-row items-center bg-white p-6 rounded-lg shadow cursor-pointer ${
      selectedFeature === feature.name ? "bg-white border shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] shadow-cyan-500/50 border-white" : "hover:bg-gray-200"
    } h-44 lg:h-auto transition-all duration-300 ease-in-out`} // Ajoutez ici la logique de changement de couleur pour l'élément actif et au survol
    onClick={() => {
      setSelectedFeature(feature.name);
      setCurrentImage(feature.image);
    }}
    style={{ minHeight: '150px' }}
  >
    <feature.icon className="h-8 w-8 text-blue-500 mr-4" aria-hidden="true" />
    <div>
      <h3 className="font-semibold text-gray-900">{feature.name}</h3>
      <p className="text-gray-500 mt-2">{feature.description}</p>
    </div>
  </div>
))}
  </div>
</div>

          <div className="flex items-start justify-end lg:order-first">
          <svg viewBox="0 0 366 729" role="img" className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
                
                <title>App screenshot</title>
                
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width={316} height={684} rx={36} />
                  </clipPath>
                </defs>
                <path
                  fill="#000"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#000"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width={316}
                  height={684}
                  transform="translate(24 24)"
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  <img src={currentImage} alt="App screenshot" className={`max-w-full h-auto ${isImageChanging ? "animate-pulse" : ""}`}/>
                </foreignObject>
              </svg>
          </div>
        </div>
      </div>
    </div>
  )
}
