import React, { useRef } from 'react';
import { Headers } from './Headers';
import { Hero } from './Hero';
import { Features } from './Features';
import { SecondaryFeatures } from './SecondaryFeatures';
import { Cta } from './Cta';
import { Pricing } from './Pricing';
import { Faq } from './Faq';
import { Footer } from './Footer';

function HomePage() {
  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const secondaryFeaturesRef = useRef(null);
  const ctaRef = useRef(null);
  const pricingRef = useRef(null);
  const faqRef = useRef(null);

  return (
    <>
      <Headers scrollRefs={{ heroRef, featuresRef, secondaryFeaturesRef, ctaRef, pricingRef, faqRef }} />
      <div ref={heroRef}><Hero /></div>
      <div ref={featuresRef}><Features /></div>
      <div ref={secondaryFeaturesRef}><SecondaryFeatures /></div>
      <div ref={ctaRef}><Cta /></div>
      <div ref={pricingRef}><Pricing /></div>
      <div ref={faqRef}><Faq /></div>
      <Footer />
    </>
  );
}

export default HomePage;
