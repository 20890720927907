import React from 'react';
import { CSVLink } from 'react-csv';

function Modal({ onClose, startDate, endDate, setStartDate, setEndDate, csvData, filename }) {
  return (
    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded">
        <h2 className="text-lg">Sélectionnez la plage de dates pour l'export CSV</h2>
        <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} className="border p-2 rounded" />
        <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} className="border p-2 rounded" />
        <CSVLink data={csvData} filename={filename} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
          Télécharger CSV
        </CSVLink>
        <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded mt-4">Fermer</button>
      </div>
    </div>
  );
}

export default Modal;
