import React from 'react';

export default function Presentation() {
  return (
    <div className="bg-white px-6 py-24 lg:px-8">
      <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">Présentation de l'Application Web</h1>
        <p className="mt-6 text-lg leading-8 text-center">
          Notre application web propose une gestion complète de vos obligations sanitaires grâce à un tableau de bord intuitif et des vues détaillées pour chaque élément de gestion.
        </p>

        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900 text-center">Tableau de Bord</h2>
        <p className="mt-4 text-center">
          Le tableau de bord vous offre une vue d'ensemble de toutes vos activités de gestion sanitaire. Vous pouvez y consulter rapidement les informations essentielles et accéder aux différentes sections pour une gestion détaillée.
        </p>
        <div className="mt-8 flex justify-center">
          <img src="logirestau-dashview.PNG" alt="Tableau de bord" className="w-full max-w-4xl rounded-lg shadow-lg" />
        </div>

        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 text-center">Vues Détailées</h2>
        <p className="mt-4 text-center">
          Explorez les vues détaillées pour une gestion approfondie de chaque élément. Notre application vous permet de suivre et de contrôler facilement vos équipes, les dates limites de consommation, les relevés de température, les marchandises, et le plan d'hygiène.
        </p>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div className="bg-gradient-to-br from-gray-50 to-sky-200 p-6 rounded-lg shadow-lg flex flex-col items-center text-center text-white">
            <h3 className="text-xl text-[#003366] font-semibold">Équipe</h3>
            <p className="mt-4 text-[#003366]">
              Gérez efficacement votre équipe avec des outils de suivi et de communication intégrés.
            </p>
            <img src="logiteam.PNG" alt="Équipe" className="w-full mt-4 rounded-lg shadow-lg" />
          </div>
          <div className="bg-gradient-to-br from-gray-50 to-sky-200 p-6 rounded-lg shadow-lg flex flex-col items-center text-center text-white">
            <h3 className="text-xl text-[#003366] font-semibold">DLC</h3>
            <p className="mt-4 text-[#003366]">
              Suivez les dates limites de consommation de vos produits pour garantir leur fraîcheur.
            </p>
            <img src="logidlc.PNG" alt="DLC" className="w-full mt-4 rounded-lg shadow-lg" />
          </div>
          <div className="bg-gradient-to-br from-gray-50 to-sky-200 p-6 rounded-lg shadow-lg flex flex-col items-center text-center text-white">
            <h3 className="text-xl text-[#003366] font-semibold">Relevé de Température</h3>
            <p className="mt-4 text-[#003366]">
              Enregistrez et surveillez les relevés de température pour assurer le respect des normes sanitaires.
            </p>
            <img src="logitemp.PNG" alt="Relevé de Température" className="w-full mt-4 rounded-lg shadow-lg" />
          </div>
          <div className="bg-gradient-to-br from-gray-50 to-sky-200 p-6 rounded-lg shadow-lg flex flex-col items-center text-center text-white">
            <h3 className="text-xl text-[#003366] font-semibold">Marchandises</h3>
            <p className="mt-4 text-[#003366]">
              Gérez vos stocks et vos marchandises de manière efficace et optimisée.
            </p>
            <img src="logimerch.PNG" alt="Marchandises" className="w-full mt-4 rounded-lg shadow-lg" />
          </div>
          <div className="bg-gradient-to-br from-gray-50 to-sky-200 p-6 rounded-lg shadow-lg flex flex-col items-center text-center text-white">
            <h3 className="text-xl text-[#003366] font-semibold">Plan d'Hygiène</h3>
            <p className="mt-4 text-[#003366]">
              Mettez en place et suivez votre plan d'hygiène pour garantir un environnement propre et sûr.
            </p>
            <img src="logimerch.PNG" alt="Plan d'Hygiène" className="w-full mt-4 rounded-lg shadow-lg" />
          </div>
        </div>
      </div>
    </div>
  );
}
