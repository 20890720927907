import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { db } from '../firebase';
import { collection, query, where, orderBy, onSnapshot, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { useUser } from '../contexts/UserContext';
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const frequencies = ['Quotidien', 'Hebdomadaire', 'Mensuel', 'Annuel'];
const methods = ['Nettoyage à sec', 'Nettoyage humide', 'Désinfection', 'Stérilisation'];

function CleaningPlanOverview() {
  const [cleaningPlans, setCleaningPlans] = useState([]);
  const [zones, setZones] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [planToDelete, setPlanToDelete] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [newZone, setNewZone] = useState('');
  const { enterpriseId } = useUser();

  useEffect(() => {
    if (!enterpriseId) return;

    const q = query(
      collection(db, "cleaningPlans"),
      where("enterpriseId", "==", enterpriseId),
      orderBy("lastControlDate", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        lastControlDate: doc.data().lastControlDate?.toDate()
      }));
      setCleaningPlans(items);
      setZones([...new Set(items.map(plan => plan.zone))]);
    });

    return () => unsubscribe();
  }, [enterpriseId]);

  const openModal = (plan) => {
    setCurrentPlan(plan);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentPlan(null);
    setNewZone('');
  };

  const openDeleteConfirm = (plan) => {
    setPlanToDelete(plan);
    setIsDeleteConfirmOpen(true);
  };

  const closeDeleteConfirm = () => {
    setPlanToDelete(null);
    setIsDeleteConfirmOpen(false);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const zone = newZone || formData.get('zone');
    const data = {
      zone,
      frequency: formData.get('frequency'),
      products: formData.get('products'),
      method: formData.get('method'),
      responsible: formData.get('responsible'),
      lastControlDate: formData.get('lastControlDate') ? new Date(formData.get('lastControlDate')) : null,
      enterpriseId
    };

    if (currentPlan) {
      await updateDoc(doc(db, "cleaningPlans", currentPlan.id), data);
    } else {
      await addDoc(collection(db, "cleaningPlans"), data);
      if (!zones.includes(zone)) {
        setZones([...zones, zone]);
      }
    }

    closeModal();
  };

  const handleDelete = async () => {
    if (planToDelete) {
      await deleteDoc(doc(db, "cleaningPlans", planToDelete.id));
      closeDeleteConfirm();
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow-lg rounded-lg p-4 overflow-x-auto">
        <h3 className="text-lg font-semibold text-[#003366] mb-4 text-center">Plans de Nettoyage</h3>
        {zones.map((zone) => (
          <div key={zone} className="mb-6">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h4 className="text-base font-semibold leading-6 text-[#003366]">{zone}</h4>
            </div>
            <table className="min-w-full divide-y divide-gray-200 mb-4">
              <thead>
                <tr>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Zone</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Fréquence</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Produits</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Méthode</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Responsable</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Dernier Contrôle</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {cleaningPlans.filter(plan => plan.zone === zone).map((plan) => (
                  <tr key={plan.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center font-medium text-gray-900">{plan.zone}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.frequency}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.products}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.method}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.responsible}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">{plan.lastControlDate ? plan.lastControlDate.toLocaleDateString("fr-FR") : 'Pas encore contrôlé'}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">
                      <button onClick={() => openModal(plan)} className="text-[#003366] hover:underline">Modifier</button>
                      <button onClick={() => openDeleteConfirm(plan)} className="text-red-600 hover:underline ml-4">Supprimer</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <div className="mt-4 text-center">
          <button onClick={() => openModal(null)} className="bg-[#003366] hover:bg-[#3399FF] text-white px-4 py-2 rounded-md">Ajouter un plan</button>
        </div>
      </div>

      {/* Modal pour ajouter ou modifier un plan */}
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="text-center sm:text-left sm:ml-4">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-[#003366]">
                        {currentPlan ? 'Modifier le plan de nettoyage' : 'Ajouter un plan de nettoyage'}
                      </Dialog.Title>
                      <div className="mt-4">
                        <form onSubmit={handleSave} className="space-y-4">
                          <div>
                            <label htmlFor="zone" className="block text-sm font-medium text-gray-700">Zone</label>
                            <select
                              name="zone"
                              id="zone"
                              defaultValue={currentPlan?.zone || ''}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                              onChange={(e) => setNewZone(e.target.value === 'new' ? '' : e.target.value)}
                              required
                            >
                              <option value="">Sélectionner une zone</option>
                              {zones.map(zone => (
                                <option key={zone} value={zone}>{zone}</option>
                              ))}
                              <option value="new">Créer une nouvelle zone</option>
                            </select>
                          </div>
                          {newZone === '' && (
                            <div>
                              <label htmlFor="newZone" className="block text-sm font-medium text-gray-700">Nouvelle Zone</label>
                              <input
                                type="text"
                                name="newZone"
                                id="newZone"
                                value={newZone}
                                onChange={(e) => setNewZone(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                                required
                              />
                            </div>
                          )}
                          <div>
                            <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">Fréquence</label>
                            <select
                              name="frequency"
                              id="frequency"
                              defaultValue={currentPlan?.frequency || ''}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                              required
                            >
                              {frequencies.map(frequency => (
                                <option key={frequency} value={frequency}>{frequency}</option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label htmlFor="products" className="block text-sm font-medium text-gray-700">Produits</label>
                            <input type="text" name="products" id="products" defaultValue={currentPlan?.products || ''} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" required />
                          </div>
                          <div>
                            <label htmlFor="method" className="block text-sm font-medium text-gray-700">Méthode</label>
                            <select
                              name="method"
                              id="method"
                              defaultValue={currentPlan?.method || ''}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                              required
                            >
                              {methods.map(method => (
                                <option key={method} value={method}>{method}</option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label htmlFor="responsible" className="block text-sm font-medium text-gray-700">Responsable</label>
                            <input type="text" name="responsible" id="responsible" defaultValue={currentPlan?.responsible || ''} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" required />
                          </div>
                          <div>
                            <label htmlFor="lastControlDate" className="block text-sm font-medium text-gray-700">Dernier Contrôle</label>
                            <input type="date" name="lastControlDate" id="lastControlDate" defaultValue={currentPlan?.lastControlDate ? currentPlan.lastControlDate.toISOString().split('T')[0] : ''} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" />
                          </div>
                          <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#003366] text-base font-medium text-white hover:bg-[#3399FF] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3399FF] sm:ml-3 sm:w-auto sm:text-sm">
                              Enregistrer
                            </button>
                            <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm" onClick={closeModal}>
                              Annuler
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Modal de confirmation de suppression */}
      <Transition.Root show={isDeleteConfirmOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDeleteConfirm}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="text-center sm:text-left sm:ml-4">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Confirmer la suppression
                      </Dialog.Title>
                      <div className="mt-4">
                        <p className="text-sm text-gray-500">
                          Êtes-vous sûr de vouloir supprimer ce plan de nettoyage ? Cette action est irréversible.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                    <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleDelete}>
                      Supprimer
                    </button>
                    <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm" onClick={closeDeleteConfirm}>
                      Annuler
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default CleaningPlanOverview;
