import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Logo } from './Logo';
import { LogoName } from './LogoName';

export function Headers({ scrollRefs }) {
  const { heroRef, featuresRef, pricingRef, faqRef } = scrollRefs;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const scrollToRef = (ref) => () => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setMobileMenuOpen(false); // Fermer le menu après la sélection
  };

  const navigation = [
    { name: 'Produit', action: scrollToRef(heroRef) },
    { name: 'Caracteristiques', action: scrollToRef(featuresRef) },
    { name: 'Offres', action: scrollToRef(pricingRef) },
    { name: 'FAQ', action: scrollToRef(faqRef) },
  ];

  return (
    <header className="bg-white z-50 relative">
      <nav className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">LogiRestau</span>
            <Logo className="h-8 w-auto" />
          </a>
          <LogoName className="ml-3 mt-1" />
        </div>

        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <button
              key={item.name}
              onClick={item.action}
              className="text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-200/50 rounded-lg cursor-pointer px-3 py-2"
            >
              {item.name}
            </button>
          ))}
        </div>

        <div className="flex flex-1 items-center justify-end gap-x-6">
          <a
            href="/login"
            className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900 hover:bg-gray-200/50 rounded-lg px-3 py-2"
          >
            Connexion
          </a>
          <a
            href="/signup"
            className="rounded-md bg-[#003366] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#3399FF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Inscription
          </a>
        </div>

        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)} // Basculer l'état ouvert/fermé
          >
            <span className="sr-only">Open main menu</span>
            {mobileMenuOpen ? (
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            ) : (
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            )}
          </button>
        </div>
      </nav>

      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between gap-x-6">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">LogiRestau</span>
              <Logo className="h-8 w-auto" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)} // Fermer quand l'utilisateur clique sur X
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <button
                    key={item.name}
                    onClick={item.action}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </button>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Connexion
                </a>
                <a
                  href="/signup"
                  className="-mx-3 mt-2 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white bg-[#003366] hover:bg-[#3399FF]"
                >
                  Inscription
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
