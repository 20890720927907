import React from 'react';

export default function MentionsLegales() {
  return (
    <div className="bg-white px-6 py-24 lg:px-8">
      <div className="mx-auto max-w-4xl text-base leading-7 text-gray-700">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Mentions Légales</h1>
        <p className="mt-6 text-lg leading-8">
          Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs du site les présentes mentions légales.
        </p>
        
        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Informations sur l'entreprise</h2>
        <div className="mt-4">
          <p><strong>Nom de l'entreprise :</strong> Logirestau</p>
          <p><strong>Forme juridique :</strong> SAS</p>
          <p><strong>Adresse :</strong> 180 bis rue de Charenton, 75012 Paris</p>
          <p><strong>Email :</strong> contact@logirestau.fr</p>
          <p><strong>Numéro de SIRET :</strong> 90790608500010</p>
        </div>

        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Propriété intellectuelle</h2>
        <p className="mt-4">
          La structure générale du site ainsi que les textes, graphiques, images, sons et vidéos la composant, sont la propriété de Logirestau ou de ses partenaires. Toute représentation et/ou reproduction et/ou exploitation partielle ou totale des contenus et services proposés par le site, par quelque procédé que ce soit, sans l'autorisation préalable et par écrit de Logirestau et/ou de ses partenaires est strictement interdite et serait susceptible de constituer une contrefaçon au sens des articles L 335-2 et suivants du Code de la propriété intellectuelle.
        </p>

        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Liens hypertextes</h2>
        <p className="mt-4">
          Le site peut contenir des liens hypertextes vers d'autres sites présents sur le réseau Internet. Les liens vers ces autres ressources vous font quitter le site logirestau.fr. Il est possible de créer un lien vers la page de présentation de ce site sans autorisation expresse de l'éditeur. Aucune autorisation ou demande d’information préalable ne peut être exigée par l’éditeur à l’égard d’un site qui souhaite établir un lien vers le site de l’éditeur. Il convient toutefois d’afficher ce site dans une nouvelle fenêtre du navigateur. Cependant, l’éditeur se réserve le droit de demander la suppression d’un lien qu’il estime non conforme à l’objet du site logirestau.fr 
        </p>

        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Responsabilité de l'éditeur</h2>
        <p className="mt-4">
          Les informations et/ou documents figurant sur ce site et/ou accessibles par ce site proviennent de sources considérées comme étant fiables. Cependant, ces informations et/ou documents sont susceptibles de contenir des inexactitudes techniques et des erreurs typographiques. L’éditeur se réserve le droit de les corriger, dès que ces erreurs sont portées à sa connaissance. Il est fortement recommandé de vérifier l'exactitude et la pertinence des informations et/ou documents mis à disposition sur ce site. Les informations et/ou documents disponibles sur ce site sont susceptibles d'être modifiés à tout moment, et peuvent avoir fait l'objet de mises à jour. En particulier, ils peuvent avoir été mis à jour entre le moment de leur téléchargement et celui où l'utilisateur en prend connaissance.
        </p>

        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Politique de confidentialité</h2>
        <p className="mt-4">
          Nous respectons votre vie privée et nous nous engageons à protéger les informations personnelles que vous nous fournissez. Nous ne collectons que les données nécessaires pour fournir nos services et nous ne vendons ni ne transmettons vos informations à des tiers sans votre consentement explicite.
        </p>
        <p className="mt-4">
          Pour plus d'informations sur la manière dont nous utilisons, stockons et protégeons vos données personnelles, veuillez consulter notre <a href="/privacy" className="text-blue-500">politique de confidentialité</a>.
        </p>

        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Contact</h2>
        <p className="mt-4">
          Pour toute question ou information complémentaire, vous pouvez nous contacter à l'adresse suivante : contact@logirestau.fr
        </p>
      </div>
    </div>
  );
}
