import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useUser } from '../contexts/UserContext';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon, CheckCircleIcon, ClockIcon } from '@heroicons/react/24/outline';

// Charger la clé publique Stripe
const stripePromise = loadStripe('pk_test_51ORksFBHy4enxmepqO7Hy89SsLaktx6jnvvcMPEzbe6T2tS8T3Gh8Aay1DPFY95lkN2qZ6IH4sSH4MLCPRj1I6J900cuMSEgic');

export function SubscriptionParameters() {
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { enterpriseId } = useUser();

  // Pour récupérer les informations de l'abonnement depuis Firestore
  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      if (enterpriseId) {
        const enterpriseDoc = await getDoc(doc(db, 'enterprises', enterpriseId));
        const enterpriseData = enterpriseDoc.data();
        setSubscriptionInfo(enterpriseData);
      }
    };

    fetchSubscriptionInfo();
  }, [enterpriseId]);

  // Fonction pour rediriger l'utilisateur vers le portail de gestion Stripe
  const handleManageSubscription = async () => {
    setIsLoading(true);
    setIsModalOpen(true);

    try {
      const functions = getFunctions(undefined, 'europe-west1');
      const createBillingPortalSession = httpsCallable(functions, 'createBillingPortalSession');

      const { data } = await createBillingPortalSession({
        enterpriseId,
        returnUrl: window.location.href, // Redirige l'utilisateur vers cette page après la gestion de l'abonnement
      });

      window.location.href = data.url; // Redirection vers Stripe Billing Portal
    } catch (error) {
      console.error('Erreur lors de la création de la session du portail de facturation :', error);
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  // Fonction pour déterminer le statut de l'abonnement
  const getSubscriptionStatus = () => {
    const today = new Date();
    const subscriptionEnd = new Date(subscriptionInfo.subscriptionEnd);
    const diffTime = subscriptionEnd - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays < 0) {
      return {
        status: 'Expiré',
        icon: <ExclamationTriangleIcon className="h-6 w-6 text-red-500" />,
        textClass: 'text-red-600',
      };
    } else if (diffDays <= 3) {
      return {
        status: `Expire dans ${diffDays} jour${diffDays > 1 ? 's' : ''}`,
        icon: <ClockIcon className="h-6 w-6 text-orange-500" />,
        textClass: 'text-orange-600',
      };
    } else {
      return {
        status: 'Actif',
        icon: <CheckCircleIcon className="h-6 w-6 text-green-500" />,
        textClass: 'text-green-600',
      };
    }
  };

  return (
    <div className="bg-white shadow sm:rounded-lg p-6">
      {subscriptionInfo && (
        <div className="space-y-6">
          {/* En-tête */}
          <div className="flex items-center justify-center space-x-3">
            <CreditCardIcon className="h-8 w-8 text-[#003366]" />
            <h3 className="text-2xl leading-6 font-bold text-[#003366]">Votre abonnement</h3>
          </div>

          {/* Informations sur l'abonnement */}
          <div className="bg-gray-50 p-6 rounded-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="text-center">
                <p className="text-sm font-medium text-gray-500">Fin de l'abonnement</p>
                <p className="mt-1 text-lg font-semibold text-gray-900">
                  {new Date(subscriptionInfo.subscriptionEnd).toLocaleDateString()}
                </p>
              </div>
              <div className="text-center">
                <p className="text-sm font-medium text-gray-500">Limite d'utilisateurs</p>
                <p className="mt-1 text-lg font-semibold text-gray-900">
                  {subscriptionInfo.userLimit}
                </p>
              </div>
              <div className="text-center">
                <p className="text-sm font-medium text-gray-500">Type d'abonnement</p>
                <p className="mt-1 text-lg font-semibold text-gray-900">
                  {subscriptionInfo.subscriptionTier === 'prod_PxiSzpPBlasw6p' ? 'Bistrot' : 'Café'}
                </p>
              </div>
              <div className="text-center">
                <p className="text-sm font-medium text-gray-500">Statut</p>
                <div className="mt-1 flex items-center justify-center">
                  {getSubscriptionStatus().icon}
                  <span className={`ml-2 text-lg font-semibold ${getSubscriptionStatus().textClass}`}>
                    {getSubscriptionStatus().status}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Bouton de gestion de l'abonnement */}
          <div className="text-center">
            <button
              type="button"
              className="inline-flex items-center bg-[#003366] hover:bg-[#002244] text-white font-semibold py-2 px-4 rounded-md shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3399FF]"
              onClick={handleManageSubscription}
            >
              Gérer mon abonnement via Stripe
            </button>
          </div>
        </div>
      )}

      {/* Modal de chargement */}
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setIsModalOpen(false)}
        >
          <div className="flex items-center justify-center min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
            </Transition.Child>

            {/* Contenu de la modal */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95 translate-y-4"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 scale-95 translate-y-4"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                <div className="flex justify-center">
                  <svg
                    className="animate-spin h-12 w-12 text-[#003366]"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
                <div className="mt-4 text-center">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-[#003366]">
                    Traitement en cours
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Votre demande est en cours de traitement. Veuillez patienter un instant.
                    </p>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default SubscriptionParameters;
