import React from 'react';

export default function Privacy() {
  return (
    <div className="bg-white px-6 py-24 lg:px-8">
      <div className="mx-auto max-w-4xl text-base leading-7 text-gray-700">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Politique de Confidentialité</h1>
        
        <p className="mt-6 text-lg leading-8">
          Cette politique de confidentialité décrit la manière dont Logirestau collecte, utilise et protège les informations personnelles que vous nous fournissez lorsque vous utilisez notre application.
        </p>
        
        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Collecte des informations personnelles</h2>
        <p className="mt-4">
          Nous collectons les informations personnelles que vous nécéssaire au bon fonctionnement de notre application : votre adresse e-mail pour vous identifiez
        </p>
        
        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Utilisation des informations</h2>
        <p className="mt-4">
          Les informations personnelles que nous collectons sont utilisées pour fournir nos services, communiquer avec vous, améliorer notre application et assurer la sécurité de nos utilisateurs.
        </p>
        
        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Protection des informations</h2>
        <p className="mt-4">
          Nous nous engageons à protéger vos informations personnelles et à ne pas les vendre, les louer ou les transmettre à des tiers sans votre consentement explicite, sauf dans les cas prévus par la loi.
        </p>
        
        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Accès et contrôle de vos informations</h2>
        <p className="mt-4">
          Vous avez le droit d'accéder à vos informations personnelles que nous détenons et de demander leur correction, mise à jour ou suppression si elles sont inexactes ou incomplètes.
        </p>
        
        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Cookies</h2>
        <p className="mt-4">
          Notre application peut utiliser des cookies pour améliorer l'expérience utilisateur. Vous pouvez choisir de refuser les cookies en modifiant les paramètres de votre navigateur, mais cela pourrait affecter certaines fonctionnalités de notre application.
        </p>
        
        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Modification de notre politique de confidentialité</h2>
        <p className="mt-4">
          Nous pouvons mettre à jour cette politique de confidentialité de temps à autre pour refléter les changements dans nos pratiques de collecte et d'utilisation des informations. Cependant vous serez toujours notifié en cas de modification de notre politique de confidentialité et nous vous demanderons d'accepter les nouvelles conditions.
        </p>
        
        <h2 className="mt-8 text-2xl font-bold tracking-tight text-gray-900">Contactez-nous</h2>
        <p className="mt-4">
          Pour toute question concernant notre politique de confidentialité ou pour exercer vos droits en matière de protection des données, veuillez nous contacter à l'adresse suivante : contact@logirestau.fr
        </p>
      </div>
    </div>
  );
}
