import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, orderBy, updateDoc, doc } from 'firebase/firestore';
import { useUser } from '../contexts/UserContext';
import ModalReception from './ModalReception';

function ReceptionDashboard() {
    const [receptions, setReceptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedReception, setSelectedReception] = useState(null);
    const [showArchived, setShowArchived] = useState(false);
    const { enterpriseId } = useUser();

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, "receptionInfo"), where("enterpriseId", "==", enterpriseId), orderBy("receptionDate"));
            const querySnapshot = await getDocs(q);
            const items = querySnapshot.docs.map(doc => ({
                id: doc.id, 
                ...doc.data(), 
                receptionDate: doc.data().receptionDate.toDate() // Convertir Firestore Timestamp en Date
            }));
            setReceptions(items);
        };
        fetchData();
    }, [enterpriseId]);

    const handleSelectReception = (item, event) => {
        event.stopPropagation(); // Pour empêcher la propagation de l'événement
        setSelectedReception(item); // Définir l'objet de réception sélectionné pour le modal
    };

    const handleCloseModal = () => {
        setSelectedReception(null);
    };

    const handleArchiveReception = async (id) => {
        const receptionDoc = doc(db, "receptionInfo", id);
        await updateDoc(receptionDoc, { archive: true });
        setReceptions(receptions.map(reception => 
            reception.id === id ? { ...reception, archive: true } : reception
        ));
    };

    // Filtrer les réceptions en fonction de l'état d'affichage des archivées et du terme de recherche
    const filteredReceptions = receptions.filter(reception => {
        return (showArchived || !reception.archive) && 
               (!searchTerm || reception.User.toLowerCase().includes(searchTerm.toLowerCase()));
    });

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Gestion des Réceptions</h1>
            <div className="flex justify-between items-center mb-4">
                <input
                    type="text"
                    placeholder="Rechercher par utilisateur"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2"
                />
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        checked={showArchived}
                        onChange={() => setShowArchived(!showArchived)}
                        className="form-checkbox h-5 w-5 text-indigo-600 ml-2"
                    />
                    <span className="ml-2 text-sm text-gray-700">Afficher les archivées</span>
                </label>
            </div>
            <div className="overflow-hidden mt-8 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Alerte</th>
                            <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Utilisateur</th>
                            <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Fournisseur</th>
                            <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Date de réception</th>
                            <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Température</th>
                            <th scope="col" className="px-6 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {filteredReceptions.map((item) => (
                            <tr key={item.id} className="cursor-pointer">
                                <td className="px-6 py-4 text-sm text-left">
                                    {item.nonConformity && (
                                        <span className="inline-block rounded-full bg-red-500 px-3 text-white">Non Conforme</span>
                                    )}
                                    {!item.nonConformity && (
                                        <span className="inline-block rounded-full bg-green-700 px-3 text-white">Conforme</span>
                                    )}
                                </td>
                                <td className="px-6 py-4 text-sm text-left text-gray-900">{item.User}</td>
                                <td className="px-6 py-4 text-sm text-left text-gray-900">{item.supplierName}</td>
                                <td className="px-6 py-4 text-sm text-left text-gray-500">{item.receptionDate.toLocaleDateString()}</td>
                                <td className="px-6 py-4 text-sm text-left text-gray-500">{item.productTemperature}°C</td>
                                <td className="px-6 py-4 text-left text-sm">
                                    <button
                                        onClick={(e) => handleSelectReception(item, e)}
                                        className="text-indigo-600 hover:text-indigo-900"
                                    >
                                        Détails
                                    </button>
                                    <button
                                        onClick={() => handleArchiveReception(item.id)}
                                        className="text-red-600 hover:text-red-900 ml-4"
                                    >
                                        Archiver
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {selectedReception && (
                <ModalReception 
                    isOpen={!!selectedReception}
                    onClose={handleCloseModal}
                    reception={selectedReception}
                />
            )}
        </div>
    );
}

export default ReceptionDashboard;
