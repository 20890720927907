import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [enterpriseId, setEnterpriseId] = useState('');
  const [subscriptionActive, setSubscriptionActive] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        const userDocRef = doc(db, 'users', firebaseUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userEnterpriseId = userDoc.data().enterpriseID || '';
          setEnterpriseId(userEnterpriseId);
          console.log("Enterprise ID:", userEnterpriseId); // Debugging line
          if (userEnterpriseId) {
            const enterpriseDocRef = doc(db, 'enterprises', userEnterpriseId);
            const enterpriseDoc = await getDoc(enterpriseDocRef);
            if (enterpriseDoc.exists()) {
              console.log("Subscription Active:", enterpriseDoc.data().subscriptionActive); // Debugging line
              setSubscriptionActive(enterpriseDoc.data().subscriptionActive || false);
            }
          }
        }
      } else {
        setUser(null);
        setEnterpriseId('');
        setSubscriptionActive(false);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, isLoading, enterpriseId, subscriptionActive }}>
      {children}
    </UserContext.Provider>
  );
};
