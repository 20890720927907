import { db, auth } from '../firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { sendSignInLinkToEmail } from 'firebase/auth';

export const getUsersByEnterpriseId = async (enterpriseId) => {
  const users = [];
  const q = query(collection(db, 'users'), where('enterpriseID', '==', enterpriseId));
  const snapshot = await getDocs(q);
  snapshot.forEach(doc => {
    users.push({ id: doc.id, ...doc.data() });
  });
  return users;
};

export const addUserToEnterprise = async (email, enterpriseId, role) => {
  const enterpriseRef = doc(db, 'enterprises', enterpriseId);
  const enterpriseDoc = await getDoc(enterpriseRef); // Correction ici
  if (!enterpriseDoc.exists()) {
    throw new Error('Enterprise not found');
  }
  const enterpriseData = enterpriseDoc.data();
  const currentUsers = await getUsersByEnterpriseId(enterpriseId);

  if (currentUsers.length >= enterpriseData.userLimit) {
    throw new Error('Limite d\'utilisateurs atteinte pour cet abonnement.');
  }

  try {
    console.log('Adding user to Firestore');
    await addDoc(collection(db, 'users'), {
      email,
      enterpriseID: enterpriseId,
      role,
      imageUrl: '', // default imageUrl, can be updated later
    });

    console.log('Sending email invitation');
    const actionCodeSettings = {
      url: 'https://logirestau.fr/complete-registration', // Update to your domain
      handleCodeInApp: true,
    };

    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem('emailForSignIn', email);
    console.log('Email sent successfully');
  } catch (error) {
    console.error('Error in addUserToEnterprise:', error);
    throw error;
  }
};

export const updateUserRole = async (userId, email, role) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, { email, role });
    console.log('User role updated successfully');
  } catch (error) {
    console.error('Error in updateUserRole:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await deleteDoc(userRef);
    console.log('User deleted successfully');
  } catch (error) {
    console.error('Error in deleteUser:', error);
    throw error;
  }
};
