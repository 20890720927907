import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FaTemperatureFull } from "react-icons/fa6";
import { CiFries, CiDeliveryTruck } from "react-icons/ci";
import { HomeIcon, UsersIcon, CalendarDaysIcon, DocumentDuplicateIcon, ChartPieIcon, Cog6ToothIcon, XMarkIcon, Bars3Icon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { Logo } from '../components/Logo';
import { LogoName } from '../components/LogoName';
import { Settings } from './Settings';
import Team from './Team';
import Overview from './Overview';
import FridgeManagement from './FridgeManagement';
import DLCDashboard from './DLCDashboard';
import ReceptionDashboard from './ReceptionDashboard';
import { useUser } from '../contexts/UserContext';
import CleaningPlanOverview from './CleaningPlanOverview';
import OilCheckPage from './OilDashboard';
import { auth } from '../firebase'; // Assurez-vous que le chemin est correct

const navigation = [
  { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
  { name: 'Equipe', href: '#', icon: UsersIcon, current: false },
  { name: 'DLC', href: '#', icon: CalendarDaysIcon, current: false },
  { name: 'Relevé temperature', href: '#', icon: FaTemperatureFull, current: false },
  { name: 'Marchandises', href: '#', icon: CiDeliveryTruck, current: false },
  { name: "Plan d'hygiene", href: '#', icon: ChartPieIcon, current: false },
  { name: "Huiles", href: '#', icon: CiFries, current: false },
];

const userNavigation = [
  { name: 'Mon compte', href: '#' },
  { name: 'Se deconnecter', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('Dashboard');
  const { enterpriseId } = useUser(); // Utiliser le contexte utilisateur

  const handleTabChange = (tabName) => {
    setCurrentTab(tabName);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      // Rediriger l'utilisateur vers la page de connexion ou une autre page appropriée
      window.location.href = '/login';
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <Logo className="h-8 w-auto" />
                      <LogoName className="ml-3 mt-1" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name} className={classNames(
                                currentTab === item.name
                                  ? 'bg-gray-50 text-[#3399FF]'
                                  : 'text-gray-700 hover:text-[#3399FF] hover:bg-gray-50'
                              )}>
                                <button
                                  onClick={() => {
                                    handleTabChange(item.name);
                                    setSidebarOpen(false); // Fermez la sidebar sur les appareils mobiles après la sélection
                                  }}
                                  className={classNames(
                                    currentTab === item.name
                                      ? 'bg-gray-50 text-[#3399FF]'
                                      : 'text-gray-700 hover:text-[#3399FF] hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      currentTab === item.name ? 'text-[#3399FF]' : 'text-gray-400 group-hover:text-[#3399FF]',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <button
                            onClick={() => handleTabChange('Settings')}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-[#3399FF]"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[#3399FF]"
                              aria-hidden="true"
                            />
                            Paramètres
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={handleLogout}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600"
                          >
                            <ArrowRightOnRectangleIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
                              aria-hidden="true"
                            />
                            Déconnexion
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <Logo className="h-8 w-auto" />
              <LogoName className="ml-3 mt-1" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name} className={classNames(
                        currentTab === item.name
                          ? 'bg-gray-50 text-[#3399FF]'
                          : 'text-gray-700 hover:text-[#3399FF] hover:bg-gray-50'
                      )}>
                        <button
                          onClick={() => handleTabChange(item.name)}
                          className={classNames(
                            currentTab === item.name
                              ? 'bg-gray-50 text-[#3399FF]'
                              : 'text-gray-700 hover:text-[#3399FF] hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              currentTab === item.name ? 'text-[#3399FF]' : 'text-gray-400 group-hover:text-[#3399FF]',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <button
                    onClick={() => handleTabChange('Settings')}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-[#3399FF]"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[#3399FF]"
                      aria-hidden="true"
                    />
                    Paramètres
                  </button>
                </li>
                <li>
                  <button
                    onClick={handleLogout}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600"
                  >
                    <ArrowRightOnRectangleIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
                      aria-hidden="true"
                    />
                    Déconnexion
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:hidden">
          <button
            type="button"
            className="fixed top-4 left-4 z-50 inline-flex items-center p-3 bg-[#003366] text-white rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <div className="lg:pl-72">
          <main className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              {currentTab === 'Settings' && <Settings />}
              {currentTab === 'DLC' && <DLCDashboard />}
              {currentTab === 'Equipe' && <Team />}
              {currentTab === 'Dashboard' && <Overview />}
              {currentTab === 'Relevé temperature' && <FridgeManagement />}
              {currentTab === 'Marchandises' && <ReceptionDashboard />}
              {currentTab === "Plan d'hygiene" && <CleaningPlanOverview />}
              {currentTab === "Huiles" && <OilCheckPage />}
             
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
