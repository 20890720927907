import React, { useState } from 'react';

function AddFridgeModal({ isOpen, onClose, onAdd }) {
    const [name, setName] = useState('');
  
    const handleSubmit = () => {
      if (!name.trim()) return; // Prevent adding empty names
      onAdd(name.trim());
      setName('');  // Reset after adding
      onClose();    // Close modal
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white rounded-lg shadow-xl p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Ajouter un nouveau frigo</h3>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nom du frigo"
            className="border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 rounded-md shadow-sm w-full p-2"
          />
          <div className="mt-4 flex justify-end gap-4">
            <button onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Ajouter</button>
            <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded">Annuler</button>
          </div>
        </div>
      </div>
    );
  }
  

  export default AddFridgeModal;