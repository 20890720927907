import React from 'react';

export function Hero() {
  return (
    <div className="bg-white -mt-16">
      <div className="relative">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <h1 className="mt-10 max-w-xl text-left text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Faciliter la gestion de votre restaurant
            </h1>
            <p className="mt-6 text-lg text-justify leading-6 text-gray-600">
              Logirestau vous permet de faire le contrôle des récéptions de marchandises, de vos températures de frigos, des DLC de vos produits. Mais aussi la possibilité d'avoir des réponses sur l'administratif et vos obligations légales grâce à son assistant intelligent.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="/signup"
                className="rounded-md bg-[#003366] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#3399FF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Commencer
              </a>
              <a href="/signup" className="text-sm font-semibold leading-6 text-gray-900 rounded-md hover:bg-[#3399FF] px-2.5 py-2 hover:text-white">
                En savoir plus <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <img
              className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
              src="/Screenshot_20240531_143824.png"
              alt="Logirestau"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
