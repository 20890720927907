import React, { useState } from 'react';
import { auth } from '../firebase.js'; // Remplacez par le chemin correct
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Logo } from '../components/Logo.js';
import { LogoName } from '../components/LogoName.js';
import { XCircleIcon } from '@heroicons/react/20/solid';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error) {
      // Gérer les erreurs de connexion ici
      setErrors([error.message]);
      console.error('Erreur lors de la connexion:', error);
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        {/* Logo et Nom */}
        <div className="text-center">
          <Logo className="mx-auto h-12 w-auto" />
          <LogoName className="mx-auto mt-2 text-3xl font-bold text-gray-900" />
          <h2 className="mt-6 text-xl font-semibold text-gray-700">
            Connectez-vous à votre compte LogiRestau
          </h2>
        </div>

        {/* Formulaire */}
        <div className="mt-8 rounded-lg bg-white py-8 px-6 shadow-md">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* Champs de saisie */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Adresse email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-[#003366] focus:ring-[#003366]"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Mot de passe
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-[#003366] focus:ring-[#003366]"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            {/* Messages d'erreur */}
            {errors.length > 0 && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      Il y a eu des erreurs avec votre soumission
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      <ul className="list-disc space-y-1 pl-5">
                        {errors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Bouton de soumission */}
            <div>
              <button
                type="submit"
                className="w-full rounded-md bg-[#003366] px-4 py-2 text-white hover:bg-[#002244] focus:outline-none focus:ring-2 focus:ring-[#003366]"
              >
                Se connecter
              </button>
            </div>

            {/* Liens supplémentaires */}
            <div className="text-center">
              <p className="mt-4 text-sm text-gray-600">
                Pas encore membre ?{' '}
                <a href="/signup" className="font-medium text-[#003366] hover:text-[#3399FF]">
                  Créer un compte
                </a>
              </p>
              <p className="mt-2 text-sm text-gray-600">
                Mot de passe oublié ?{' '}
                <a href="/reset-password" className="font-medium text-[#003366] hover:text-[#3399FF]">
                  Réinitialiser le mot de passe
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
