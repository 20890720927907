import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { NotFound } from './components/NotFound';
import HomePage from './components/HomePage';
import Login from './auth/Login';
import SignUp from './auth/SignUp';
import ResetPassword from './auth/ResetPassword';
import SubActivcateRoute from './protected/SubActivateRoute';
import PrivateRoute from './protected/PrivateRoute';
import { Dashboard } from './dashboard/Dashboard';
import { Settings } from './dashboard/Settings';
import { UserProvider } from './contexts/UserContext';
import Subscribe from './components/Subscribe';
import SuccessPage from './components/SucessPage';
import CompleteRegistration from './auth/CompleteRegistration';
import CompleteResetPassword from './components/CompleteResetPassword';
import MoreInfo from './components/MoreInfo';
import Contact from './components/Contact';
import MentionsLegales from './components/Legales';
import Presentation from './components/PresensationWeb';
import MobileAppPresentation from './components/MobileAppPresentation';
import { analytics, logEvent } from './firebase'; // Import logEvent
import AfterSignup from './components/AfterSignup';
import Privacy from './components/Privacy';

// Create a component to log page views
const AnalyticsLogger = () => {
  const location = useLocation();

  useEffect(() => {
    // Log the page view with Firebase Analytics
    logEvent(analytics, 'page_view', { page_path: location.pathname });
  }, [location]);

  return null;
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <UserProvider>
          <AnalyticsLogger /> {/* Add this line */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/infos" element={<MoreInfo />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/legales" element={<MentionsLegales />} />
            <Route path='/web-app' element={<Presentation />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/mobile-app' element={<MobileAppPresentation />} />
            <Route path='/complete-registration' element={<CompleteRegistration />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/complete-reset-password" element={<CompleteResetPassword />} />
            <Route path="/forgot-password" element={<ResetPassword />} />
            <Route path="/welcome" element={<AfterSignup/>} />
            <Route path="/dashboard" element={<SubActivcateRoute><Dashboard /></SubActivcateRoute>} />
            <Route path="/settings" element={<SubActivcateRoute><Settings /></SubActivcateRoute>} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </UserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
