import React from 'react';
import { useNavigate } from 'react-router-dom';

function SuccessPage() {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-xl rounded-lg p-8 max-w-md text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-4">Merci pour votre achat !</h1>
        <p className="text-gray-700 mb-6">
          Nous apprécions votre confiance en nous et nous engageons à fournir les meilleures expériences possibles.
        </p>
        <button
          onClick={goToDashboard}
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg"
        >
          Continuer vers le tableau de bord
        </button>
      </div>
    </div>
  );
}

export default SuccessPage;
