import React from 'react';

function formatDate(date) {
    if (date && typeof date.toDate === 'function') {
        return date.toDate().toLocaleDateString();
    } else if (date instanceof Date) {
        return date.toLocaleDateString();
    } else {
        console.error('Invalid date:', date);
        return 'Non spécifiée'; // Ou gérer autrement selon vos besoins
    }
}

function formatDateTime(timestamp) {
    if (timestamp && typeof timestamp.toDate === 'function') {
        return timestamp.toDate().toLocaleString();
    } else if (timestamp instanceof Date) {
        return timestamp.toLocaleString();
    } else {
        console.error('Invalid timestamp:', timestamp);
        return 'Non spécifié'; // Ou gérer autrement selon vos besoins
    }
}

function ModalDlc({ isOpen, onClose, title, children, item }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg">
                <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
                        <span className="sr-only">Close</span>
                        &#x2715;
                    </button>
                </div>
                <div className="mt-4">
                    <img src={item.image_url} alt="DLC" className="w-full object-cover h-48 rounded-md"/>
                    <a href={item.image_url} download className="text-blue-500 hover:text-blue-700 block mt-2">Télécharger l'image</a>
                    <p className="mt-4"><strong>Nom :</strong> {item.name}</p>
                    <p><strong>Date de fabrication :</strong> {formatDate(item.manufacturingDate)}</p>
                    <p><strong>Date de réception :</strong> {formatDate(item.receptionDate)}</p>
                    <p><strong>Date d'expiration :</strong> {formatDate(item.expirationDate)}</p>
                    <p><strong>Créé le :</strong> {formatDateTime(item.timestamp)}</p>
                    <p><strong>Archivé :</strong> {item.archived ? "Oui" : "Non"}</p>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ModalDlc;
