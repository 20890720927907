import React from 'react';

const iosLogo = "https://logodownload.org/wp-content/uploads/2021/11/ios-logo-0.png";
const androidLogo = "https://upload.wikimedia.org/wikipedia/commons/d/d7/Android_robot.svg";

export default function MobileAppPresentation() {
  return (
    <div className="bg-white px-6 py-24 lg:px-8">
      <div className="mx-auto max-w-7xl text-base leading-7 text-gray-700">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">Téléchargez nos Applications Mobiles</h1>
        <p className="mt-6 text-lg leading-8 text-center">
          Notre application mobile est disponible sur iOS et Android. Téléchargez-la dès maintenant pour gérer vos obligations sanitaires où que vous soyez.
        </p>

        <div className="mt-16 flex justify-center space-x-8">
          <div className="flex flex-col items-center">
            <img src={iosLogo} alt="iOS Logo" className="h-24 mb-4" />
            <a href="#" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-black hover:bg-gray-600">
              Télécharger sur l'App Store
            </a>
          </div>
          <div className="flex flex-col items-center">
            <img src={androidLogo} alt="Android Logo" className="h-24 mb-4" />
            <a href="#" className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700">
              Télécharger sur Google Play
            </a>
          </div>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div className="p-6 rounded-lg shadow-lg flex flex-col items-center text-center bg-gray-100">
            <h3 className="text-xl font-semibold text-gray-900">Application iOS</h3>
            <p className="mt-4 text-gray-700">
              Téléchargez notre application iOS pour une expérience optimale sur votre iPhone ou iPad. Gérez vos obligations sanitaires en toute simplicité.
            </p>
            <img src="/Screenshot_20240531_143824.png" alt="iOS App Screenshot" className="w-64 h-auto mt-4 rounded-lg shadow-lg" />
          </div>
          <div className="p-6 rounded-lg shadow-lg flex flex-col items-center text-center bg-gray-100">
            <h3 className="text-xl font-semibold text-gray-900">Application Android</h3>
            <p className="mt-4 text-gray-700">
              Téléchargez notre application Android pour une expérience optimale sur votre smartphone ou tablette. Gérez vos obligations sanitaires en toute simplicité.
            </p>
            <img src="/Screenshot_overview.png" alt="Android App Screenshot" className="w-64 h-auto mt-4 rounded-lg shadow-lg" />
          </div>
        </div>
      </div>
    </div>
  );
}
