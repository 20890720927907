import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Assurez-vous que ce chemin est correct
import { useUser } from '../contexts/UserContext';
import { getAuth, updatePassword, deleteUser, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { SubscriptionParameters } from './SubscriptionParameters'; // Assurez-vous d'importer ce composant

const secondaryNavigation = [
  { name: 'Compte', href: '#', current: true },
  { name: 'Abonnement', href: '#', current: false },
];

export function Settings() {
  const [currentView, setCurrentView] = useState('Compte'); // Gère l'affichage conditionnel
  const [enterpriseInfo, setEnterpriseInfo] = useState({});
  const { enterpriseId } = useUser();
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchEnterpriseInfo = async () => {
      if (enterpriseId) {
        const docRef = doc(db, "enterprises", enterpriseId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setEnterpriseInfo(docSnap.data());
        } else {
          console.log("No such document!");
        }
      }
    };
    fetchEnterpriseInfo();
  }, [enterpriseId]);

  // Fonction pour supprimer le compte utilisateur
  const handleDeleteAccount = async () => {
    try {
      await deleteUser(user);
      alert('Votre compte a été supprimé avec succès.');
    } catch (error) {
      console.error('Erreur lors de la suppression du compte :', error);
      alert('Échec de la suppression du compte.');
    }
  };

  // Fonction pour réauthentifier et modifier le mot de passe
  const handleChangePassword = async (event) => {
    event.preventDefault();
    
    const { currentPassword, newPassword, confirmPassword } = passwords;
    if (newPassword !== confirmPassword) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      alert('Mot de passe mis à jour avec succès !');
    } catch (error) {
      console.error('Erreur lors de la modification du mot de passe :', error);
      alert('Erreur lors de la modification du mot de passe.');
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const { enterprisename, siret, address } = event.target.elements;
    try {
      await updateDoc(doc(db, "enterprises", enterpriseId), {
        enterpriseName: enterprisename.value,
        siret: siret.value,
        address: address.value,
      });
      alert("Informations mises à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour des informations :", error);
    }
  };

  return (
    <>
      <div className=''>
        <main>
          <h1 className="sr-only">Account Settings</h1>

          <header className="border-b border-white/5">
            <nav className="flex overflow-x-auto py-4">
              <ul role="list" className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-300 sm:px-6 lg:px-8">
                {secondaryNavigation.map((item) => (
                  <li key={item.name}>
                    <button
                      onClick={() => setCurrentView(item.name)}
                      className={currentView === item.name ? 'text-[#003366]' : ''}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </header>

          {currentView === 'Compte' && (
            <div className="divide-y divide-white/5">
              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-left font-semibold leading-7 text-[#003366]">Information sur l'entreprise</h2>
                  <p className="mt-1 text-sm text-left leading-6 text-gray-400">
                    Renseignez ici les informations sur votre entreprise .
                  </p>
                </div>

                <form onSubmit={handleUpdate} className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label htmlFor="enterprisename" className="block text-left text-sm font-medium leading-6 text-[#003366]">
                        Nom de l'entreprise
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="enterprisename"
                          id="enterprisename"
                          defaultValue={enterpriseInfo.enterpriseName || ''}
                          className="block w-full rounded-md border-0 bg-[#003366]/5 py-1.5 text-[#003366] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#3399FF] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="siret" className="text-left block text-sm font-medium leading-6 text-[#003366]">
                        SIRET
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="siret"
                          id="siret"
                          defaultValue={enterpriseInfo.siret || ''}
                          className="block w-full rounded-md border-0 bg-[#003366]/5 py-1.5 text-[#003366] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#3399FF] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label htmlFor="address" className="block text-left text-sm font-medium leading-6 text-[#003366]">
                        Adresse de l'entreprise
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="address"
                          id="address"
                          defaultValue={enterpriseInfo.address || ''}
                          className="block w-full rounded-md border-0 bg-[#003366]/5 py-1.5 text-[#003366] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#3399FF] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="email" className="block text-left text-sm font-medium leading-6 text-[#003366]">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          defaultValue={enterpriseInfo.email || ''}
                          className="block w-full rounded-md border-0 bg-[#003366]/5 py-1.5 text-[#003366] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#3399FF] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="rounded-md bg-[#003366] px-3 col-span-full py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                      Sauvegarder
                    </button>
                  </div>
                </form>
              </div>

              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-left font-semibold leading-7 text-[#003366]">Changer le mot de passe</h2>
                  <p className="mt-1 text-sm text-left leading-6 text-gray-400">
                    Mettez à jour votre mot de passe.
                  </p>
                </div>

                <form onSubmit={handleChangePassword} className="md:col-span-2">
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full">
                      <label htmlFor="current-password" className="block text-sm font-medium text-left leading-6 text-[#003366]">
                        Mot de passe actuel
                      </label>
                      <div className="mt-2">
                        <input
                          id="current-password"
                          name="current_password"
                          type="password"
                          value={passwords.currentPassword}
                          onChange={(e) => setPasswords({ ...passwords, currentPassword: e.target.value })}
                          className="block w-full rounded-md border-0 bg-[#003366]/5 py-1.5 text-[#003366] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#3399FF] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="new-password" className="block text-left text-sm font-medium leading-6 text-[#003366]">
                        Nouveau mot de passe
                      </label>
                      <div className="mt-2">
                        <input
                          id="new-password"
                          name="new_password"
                          type="password"
                          value={passwords.newPassword}
                          onChange={(e) => setPasswords({ ...passwords, newPassword: e.target.value })}
                          className="block w-full rounded-md border-0 bg-[#003366]/5 py-1.5 text-[#003366] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#3399FF] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label htmlFor="confirm-password" className="block text-left text-sm font-medium leading-6 text-[#003366]">
                        Confirmer le mot de passe
                      </label>
                      <div className="mt-2">
                        <input
                          id="confirm-password"
                          name="confirm_password"
                          type="password"
                          value={passwords.confirmPassword}
                          onChange={(e) => setPasswords({ ...passwords, confirmPassword: e.target.value })}
                          className="block w-full rounded-md border-0 bg-[#003366]/5 py-1.5 text-[#003366] shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-[#3399FF] sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="rounded-md bg-[#003366] col-span-full px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                      Sauvegarder
                    </button>
                  </div>
                </form>
              </div>

              <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                  <h2 className="text-left font-semibold leading-7 text-[#003366]">Supprimer le compte</h2>
                  <p className="mt-1 text-left text-sm leading-6 text-gray-400">
                    Cette action est définitive et supprimera votre compte.
                  </p>
                </div>

                <div className="md:col-span-2">
                  <button
                    onClick={handleDeleteAccount}
                    className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                  >
                    Oui, supprimer mon compte
                  </button>
                </div>
              </div>
            </div>
          )}

          {currentView === 'Abonnement' && (
            <SubscriptionParameters />
          )}
        </main>
      </div>
    </>
  );
}

export default Settings;
