import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

function EditDlcModal({ isOpen, onClose, dlcItem }) {
    const [name, setName] = useState(dlcItem.name);
    const [isArchived, setIsArchived] = useState(dlcItem.archived);

    const handleSaveChanges = async () => {
        const dlcDocRef = doc(db, "dlcInfo", dlcItem.id);
        await updateDoc(dlcDocRef, {
            name: name,
            archived: isArchived
        });
        onClose(); // Close modal after update
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
            <div className="bg-white rounded-lg shadow-xl p-6 space-y-4">
                <h3 className="text-lg font-semibold text-gray-900">Éditer le DLC</h3>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full rounded-md border-gray-300 shadow-sm p-2"
                    placeholder="Nom du produit"
                />
                <div className="flex items-center justify-start">
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            checked={isArchived}
                            onChange={(e) => setIsArchived(e.target.checked)}
                            className="rounded"
                        />
                        Archiver
                    </label>
                </div>
                <div className="flex justify-end gap-2">
                    <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded">Annuler</button>
                    <button onClick={handleSaveChanges} className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">Sauvegarder</button>
                </div>
            </div>
        </div>
    );
}

export default EditDlcModal;
