import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
      id: 1,
      question: "Quels types de paiements acceptez-vous ?",
      answer:
        "Nous acceptons uniquement les paiements par carte de crédit pour le moment. N'hésitez pas à nous contacter pour plus de détails.",
    },
    {
      id: 2,
      question: "Comment puis-je annuler mon abonnement ?",
      answer:
        "Vous pouvez annuler votre abonnement à tout moment depuis votre espace client. Si vous rencontrez des difficultés, notre service client est là pour vous aider.",
    },
    {
      id: 3,
      question: "Offrez-vous une période d'essai gratuite ?",
      answer:
        "Oui, nous offrons une période d'essai de 15 jours pendant laquelle vous pouvez tester toutes les fonctionnalités de notre application sans engagement.",
    },
    {
      id: 4,
      question: "Puis-je changer de plan à tout moment ?",
      answer:
        "Absolument, vous pouvez changer de plan à tout moment en fonction de vos besoins. Les modifications seront appliquées immédiatement.",
    },
    {
      id: 5,
      question: "Comment sécurisez-vous les données de vos utilisateurs ?",
      answer:
        "La sécurité de vos données est notre priorité. Nous utilisons des protocoles de chiffrement et des mesures de sécurité rigoureuses pour protéger vos informations.",
    },
    // Plus de questions si nécessaire...
  ]
  
  
  export function Faq() {
    return (
      <div className="bg-[#003366]">
         <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Foire aux questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-left leading-7 text-gray-300">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}