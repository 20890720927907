import React, { useState, useEffect } from 'react';
import { auth } from '../firebase'; // Remplacez par le chemin correct
import { useNavigate } from 'react-router-dom';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { Logo } from '../components/Logo';
import { LogoName } from '../components/LogoName';

export default function CompleteResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [oobCode, setOobCode] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const oobCodeParam = urlParams.get('oobCode');

    if (oobCodeParam) {
      setOobCode(oobCodeParam);
      verifyPasswordResetCode(auth, oobCodeParam)
        .then(() => {
          setIsVerified(true);
        })
        .catch((error) => {
          setError('Lien de réinitialisation invalide ou expiré.');
        });
    } else {
      setError('Lien de réinitialisation invalide ou expiré.');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, password);
      setMessage('Votre mot de passe a été réinitialisé avec succès.');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setError('Erreur lors de la réinitialisation du mot de passe.');
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <Logo className="h-8 w-auto" />
      <LogoName className="ml-3 mt-1" />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Réinitialisez votre mot de passe
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {isVerified ? (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                Nouveau mot de passe
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="confirm-password" className="block text-sm font-medium leading-5 text-gray-700">
                Confirmez le mot de passe
              </label>
              <div className="mt-1">
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="appearance-none block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>

            {message && <p className="text-sm text-green-600">{message}</p>}
            {error && <p className="text-sm text-red-600">{error}</p>}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-[#003366] px-4 py-2 text-sm font-medium text-white shadow hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Réinitialiser le mot de passe
              </button>
            </div>
          </form>
        ) : (
          <p className="text-center text-sm text-gray-600">{error}</p>
        )}
      </div>
    </div>
  );
}
